import ReactOnRails from 'react-on-rails';
import ClientSuggest from '../components/ClientSuggest';
import UpdateStatusButton from '../components/UpdateStatusButton';
import Zipcode from "../components/Zipcode";
import DocumentCreateButton from '../components/DocumentCreateButton';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  ClientSuggest,
  UpdateStatusButton,
  Zipcode,
  DocumentCreateButton,
});
