import React, { useState }from "react"
import { usePostalJp } from 'use-postal-jp'

const Zipcode =  () => {
  const [value, setValue] = useState('');
  const [address,loading] = usePostalJp(value,value.length >= 8);
  return (
    <>
      <div className='form-group'>
        <label htmlFor="staff_account_zipcode">郵便番号</label>
      <input
        type='text'
        onChange={(e) => setValue(e.target.value)}
        placeholder='（例）111-1111'
        className='form-control'
        id= 'form_zipcode'
        maxLength='8'
        name='staff_account[zipcode]'
        />
      </div>
      {!loading &&
      <>
        <div className='form-group'>
        <label htmlFor="staff_account_address1">都道府県</label>
        <input
          type='test'
          placeholder='（例）東京都'
          className='form-control'
          id='form_prefecture'
          name='staff_account[address1]'
          defaultValue={address ? address.prefecture : ''}
        />
        </div>
        <div className='form-group'>
          <label htmlFor="staff_account_address2">市区町村</label>
          <input
            type='test'
            placeholder='（例）中央区'
            className='form-control'
            id='form_prefecture'
            name='staff_account[address2]'
            defaultValue={address ? address.address1 : ''}
          />
        </div>
        <div className='form-group'>
          <label htmlFor="staff_account_address3">市区町村</label>
          <input
            type='test'
            placeholder='（例）東日本橋2-4-1 アドバンテージ1ビル 3F'
            className='form-control'
            id='form_prefecture'
            name='staff_account[address3]'
            defaultValue={address ? address.address2 : ''}
          />
        </div>
      </>
      }
    </>
  )
}
export default Zipcode;
