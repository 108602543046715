import React from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

const defaultValue = {id: 0, value: ''};

const ClientSuggest = (props) => {
  const selectedClient = props.company ? props.company : defaultValue;
  const promiseOptions = (inputValue) => {
    return axios.get(`/api/v1/apps/companies?keyword=${inputValue}`)
        .then(({data}) => {
          return data.map(d=> ({value: d.id, label: d.name}));
        });
  };

  return (
    <AsyncSelect
      id="partner_company_id"
      name="partner[company_id]"
      isClearable
      cacheOptions
      defaultValue={{value: selectedClient.id, label: selectedClient.name}}
      loadOptions={promiseOptions}
      defaultOptions
      placeholder='選択してください'
    />
  );
};

export default ClientSuggest;
