import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment'
import Axios from "../lib/axios";
import { useEffect } from 'react';

const UpdateStatusButton = ({
  message,
  link,
  submitButtonStyle,
  formKey,
  label
}) => {
  const [paymentDateTd, setPaymentDateTd] = useState(null);
  const [submitButtonProcessing, setSubmitButtonProcessing] =useState(false);
  let paymentDateObj, paymentDate;
  const myRef = React.createRef();

  useEffect(() => {
    paymentDateObj = myRef.current.closest('tbody.ta_center').querySelector(`#${formKey}`);
    paymentDate = moment(paymentDateObj.value, "YYYY-MM-DDTHH:mm");
  }, [myRef]);

  const submitHandler = () => {
    if (submitButtonProcessing){
      return;
    }
    paymentDateObj = myRef.current.closest('tr').querySelector(`#${formKey}`);
    paymentDate = moment(paymentDateObj.value, "YYYY-MM-DDTHH:mm");

    if (!paymentDate.isValid() || submitButtonProcessing){
      setPaymentDateTd(paymentDateObj.parentNode);
      return;
    } // 日付が入力されていないか既に送信ボタン押されていたら何もしない

    setSubmitButtonProcessing(true);

    Axios.post(link, {date: paymentDate._i})
      .then(({data}) => {
        window.location.reload();
      })
      .catch(error => {
        setSubmitButtonProcessing(false);
      });
  }

  const WarningMessage = () => {
    if(!paymentDateTd) return <></>

    return ReactDOM.createPortal(
      <div className={"text-danger"}>{`${label}を入力してください`}</div>
      , paymentDateTd)
  }

  return (
    <div>
      {paymentDateTd && <WarningMessage />}
      <a
        className={submitButtonProcessing ? 'button form-control disabled text-secondary p-1' : `${submitButtonStyle}  p-1`}
        ref={myRef}
        onClick={submitHandler}
      >
        <span>{submitButtonProcessing ? '送信中...' : message}</span>
      </a>
      {paymentDateTd && <WarningMessage />}
    </div>
  )
};

export default UpdateStatusButton;