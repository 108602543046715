import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment'
import Axios from "../lib/axios";
import { useEffect } from 'react';

const DocumentCreateButton = ({
  message,
  link,
  submitButtonStyle,
}) => {
  const [paymentDateTd, setPaymentDateTd] = useState(null);
  const [submitButtonProcessing, setSubmitButtonProcessing] =useState(false);
  let issuedDateForm, issuedDate;
  let dueDateForm, dueDate;
  const myRef = React.createRef();

  useEffect(() => {
    issuedDateForm = myRef.current.closest('tbody.ta_center').querySelector(`#issued_date`);
    issuedDate = moment(issuedDateForm.value, "YYYY-MM-DDTHH:mm");
  }, [myRef]);

  useEffect(() => {
    dueDateForm = myRef.current.closest('tbody.ta_center').querySelector(`#due_date`);
    dueDate = moment(dueDateForm.value, "YYYY-MM-DDTHH:mm");
  }, [myRef]);

  const submitHandler = () => {
    if (submitButtonProcessing){
      return;
    }
    issuedDateForm = myRef.current.closest('tr').querySelector(`#issued_date`);
    issuedDate = moment(issuedDateForm.value, "YYYY-MM-DDTHH:mm");

    dueDateForm = myRef.current.closest('tr').querySelector(`#due_date`);
    dueDate = moment(dueDateForm.value, "YYYY-MM-DDTHH:mm");


    if (!issuedDate.isValid() || !dueDate.isValid() || submitButtonProcessing){
      setPaymentDateTd(issuedDateForm.parentNode);
      return;
    } // 日付が入力されていないか既に送信ボタン押されていたら何もしない

    setSubmitButtonProcessing(true);

    Axios.post(link, {issue_date: issuedDate._i, due_date: dueDate._i})
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setSubmitButtonProcessing(false);
      });
  }

  const WarningMessage = () => {
    if(!paymentDateTd) return <></>
    return ReactDOM.createPortal(<div className={"text-danger"}>発行日と入金期日を入力してください</div>, paymentDateTd)
  }

  return (
    <div>
      {paymentDateTd && <WarningMessage />}
      <a
        className={submitButtonProcessing ? 'button form-control disabled text-secondary p-1' : `${submitButtonStyle}  p-1`}
        ref={myRef}
        onClick={submitHandler}
      >
        <span>{submitButtonProcessing ? '送信中...' : message}</span>
      </a>
      {paymentDateTd && <WarningMessage />}
    </div>
  )
};

export default DocumentCreateButton;